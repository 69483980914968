import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import GlobalStyles from "./GlobalStyles";
import Header from "./Header";
import Homepage from "./Homepage";
import NewCrossing from "./NewCrossing";
import styled from "styled-components";
import background from "./assets/background.png";


const App = () => {
  const [adminWindow, setAdminWindow] = useState(false);
  const [userArray, setUserArray] = useState([]);
  const [rulesOpen, setRulesOpen] = useState(false);
  const [displayDirections, setDisplayDirections] = useState(false);
  const [nextPage, setNextPage] = useState(true);

  const { user, isAuthenticated } = useAuth0();
  
  useEffect(() => {
    if (isAuthenticated && user) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/get-users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: user.sub }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 200) {
            // Sort userArray based on the length of the visitedlocations array
            const sortedUsers = data.data.sort(
              (a, b) => b.visitedLocations.length - a.visitedLocations.length
            );
            setUserArray(sortedUsers);
          } else {
            console.error("Failed to fetch users");
          }
        })
        .catch((error) => {
          console.error("An error occurred while fetching users:", error);
        });
    }
    setRulesOpen(!isAuthenticated);
  }, [isAuthenticated, user]);

  const adminPopup = () => {
    setAdminWindow((prev) => !prev);
  };

  return (
    <BrowserRouter>
      <GlobalStyles />
      <StyledBg background={background}>
        <Header
          adminPopup={adminPopup}
          userArray={userArray}
          setRulesOpen={setRulesOpen}
          rulesOpen={rulesOpen}
          displayDirections={displayDirections}
          setDisplayDirections={setDisplayDirections}
          setNextPage={setNextPage}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Homepage
                adminWindow={adminWindow}
                userArray={userArray}
                adminPopup={adminPopup}
                setRulesOpen={setRulesOpen}
                rulesOpen={rulesOpen}
                displayDirections={displayDirections}
                setDisplayDirections={setDisplayDirections}
                setNextPage={setNextPage}
                nextPage={nextPage}
              />
            }
          ></Route>
          <Route path="/newcrossing" element={<NewCrossing />}></Route>
        </Routes>
      </StyledBg>

    </BrowserRouter>
  );
};

const StyledBg = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff9f3;
  background-image: url(${(props) => props.background});
  background-size: auto 100%;
`
export default App;
