import styled from "styled-components";
import cfclogo2 from "./assets/cfc-logo2.png";

const Footer = () => {
  return (
    <StyledFooter>
      <StyledLogo src={cfclogo2} alt="cfc-logo" />
      <StyledP>
        Follow → @concordiafoodcoalition for info and game updates!
      </StyledP>
    </StyledFooter>
  );
};

const StyledP = styled.div`
  font-size: 0.8rem;
  white-space: nowrap;
  @media (max-width: 650px) {
    white-space: normal;
  }
  @media (max-width: 550px) {
    font-size: 0.5rem;
  }
`;

const StyledFooter = styled.div`
  position: fixed; /* Change position to fixed */
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  z-index: 0;
  bottom: 0;
  left: 0; /* Anchor to the left edge */
  right: 0; /* Anchor to the right edge */
`;

const StyledLogo = styled.img`
  height: 75px;
`;
export default Footer;
