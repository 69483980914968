import React from "react";
import App from "./App";
import ReactDOM from "react-dom/client";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { Auth0Provider } from "@auth0/auth0-react";


const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID } =
  window.__RUNTIME_CONFIG__;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={REACT_APP_AUTH0_DOMAIN}
    clientId={REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{ redirect_uri: window.location.origin }}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>
);
