import styled from "styled-components";


const ScanLocation = ({ scannerPopup }) => {
  return <StyledScanner onClick={scannerPopup}>Scan Location QR</StyledScanner>;
};

const StyledScanner = styled.button`
  transition: all 0.3s ease;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
  color: #fe46a5;
  box-shadow: rgba(254, 70, 165, 0.4) 5px 5px, rgba(254, 70, 165, 0.3) 10px 10px,
    rgba(254, 70, 165, 0.2) 15px 15px, rgba(254, 70, 165, 0.1) 20px 20px,
    rgba(254, 70, 165, 0.05) 25px 25px;
  :hover {
    transform: translateY(3px);
    box-shadow: rgba(254, 70, 165, 0.6) 5px 5px, rgba(254, 70, 165, 0.4) 8px 8px,
      rgba(254, 70, 165, 0.3) 11px 11px, rgba(254, 70, 165, 0.2) 14px 14px,
      rgba(254, 70, 165, 0.1) 17px 17px;
  }
  :active {
    transform: translateY(3px);
    box-shadow: rgba(254, 70, 165, 0.6) 5px 5px, rgba(254, 70, 165, 0.4) 8px 8px,
      rgba(254, 70, 165, 0.3) 11px 11px, rgba(254, 70, 165, 0.2) 14px 14px,
      rgba(254, 70, 165, 0.1) 17px 17px;
  }
`;

export default ScanLocation;
