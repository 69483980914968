const RulesPage2 = () => {
  return (
    <>
      <h2>Rules & Instructions 2</h2>
      <p>
        Complete the tour by finding and scanning location at least 8 of 
        the 11 landmarks around the SGW campus.
      </p>
      <p>
        Once you've reached 8, you will be automatically entered to win a 
        $20 gift card for one of the community run food spots, like the 
        Hive Cafe or Frigo Vert and Farmers Market. 
      </p>
      <p>
        Checking in at all 11 locations automatically enters you into a 
        raffle to win a prize basket containing various goods from the 
        vendors featured at our weekly Farmers' Market!
      </p>
      <p>
        Prizes will be announced every Friday in September; you'll get 
        an email to let you know and explain how to claim your prize. 
        You must claim your prize by picking it up in person at the 
        SGW Farmers' Market on the following Wednesday afternoons, between 3pm - 6pm.  
      </p>
      <p>
        For any questions please email market@concordiafoodcoalition.com 
      </p>
    </>
  );
};

export default RulesPage2;
