import styled from "styled-components";
import MapContainer from "./MapContainer";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AdminPage from "./AdminPage";
import RulesPopup from "./Rules";
import DirectionsDialog from "./DirectionsDialog";
import Footer from "./Footer";

const HomePage = ({
  adminWindow,
  userArray,
  adminPopup,
  rulesOpen,
  setRulesOpen,
  displayDirections,
  setDisplayDirections,
  setNextPage,
  nextPage,
}) => {
  const [scannerOpen, setScannerOpen] = useState(false);

  const { user, isAuthenticated, isLoading } = useAuth0();

  const scannerPopup = () => {
    if (!isAuthenticated) {
      window.alert("Please log in to scan location!");
    } else {
      setScannerOpen((pre) => !pre);
    }
  };
  //${process.env.REACT_APP_BACKEND_URL}
  useEffect(() => {
    const createUserOnLogin = async () => {
      if (isAuthenticated && user) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/create-user`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ user }),
            }
          );
          if (response.ok) {
            const data = await response.json();
            // Handle the response data if needed
          } else {
            throw new Error("Failed to create user");
          }
        } catch (error) {
          console.error("Error creating user:", error);
          // Handle error scenarios, such as displaying an error message to the user
        }
      }
    };
    if (!isLoading) {
      createUserOnLogin();
    }
  }, [isLoading, isAuthenticated, user]);

  return (
    <StyledBg>
      {rulesOpen && (
        <RulesPopup
          setRulesOpen={setRulesOpen}
          setNextPage={setNextPage}
          nextPage={nextPage}
        />
      )}

      {displayDirections && (
        <DirectionsDialog
          setDisplayDirections={setDisplayDirections}
        />
      )}

      {adminWindow && (
        <AdminPage userArray={userArray} adminPopup={adminPopup} />
      )}

      <MapContainer
        isAuthenticated={isAuthenticated}
        user={user}
        scannerPopup={scannerPopup}
        scannerOpen={scannerOpen}
      />
      <StyledFooter>
        <Footer />
      </StyledFooter>
    </StyledBg>
  );
};

const StyledFooter = styled.div`
  @media (max-height: 600px) {
    display: none;
  }
`;

const StyledBg = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

export default HomePage;
