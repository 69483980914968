import React from "react";
import styled from "styled-components";


const ProfilePicture = ({ picture, name }) => {
  const getColor = () => {
    // Add your logic to determine the color based on user data
    // For example, you can use the user's ID or any other property
    // to generate a unique color for each user
    const colors = ["#006F56", "#25408F", "#8CAED4", "#58B947"];
    const index = Math.floor(Math.random() * colors.length);
    return colors[index];
  };

  const getInitials = () => {
    const initials = name ? name.charAt(0).toUpperCase() : "";
    return initials;
  };

  const style = {
    backgroundColor: getColor(),
  };

  return (
    <div className="profile-picture" style={style}>
      {picture ? (
        <StyledPfp src={picture} alt="Profile Picture" />
      ) : (
        <StyledSpan>{getInitials()}</StyledSpan>
      )}
    </div>
  );
};
const StyledSpan = styled.span`
font-size: 3rem;
`
const StyledPfp = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  max-height: 70px;
`;

export default ProfilePicture;
