const LandingPage = () => {
  return (
    <>
      <h2>Welcome to the ConU Food Revolution Game!</h2>
      <p>
        Use this interactive map to tour some historic milestones in the student
        food movement at Concordia and win prizes!
      </p>
      <p>
        Visit the real-world landmarks (posters & art) on the Concordia campus
        and uncover stories, facts, and further readings about what students
        accomplished there!
      </p>
      <p>
        Win by scanning the QR codes linked to this map to check-in at all the
        sites.
      </p>
      <p>Create your free account to start playing now!</p>
    </>
  );
};

export default LandingPage;
