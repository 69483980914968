const RulesPage = () => {
  return (
    <>
      <h2>Rules & Instructions</h2>
      <p>In order to win prizes, you must create a free account and log in.</p>
      <p>
      Each location pin on this digital map corresponds with a real world landmark:
       a physical poster at that location, with a pink/green pin with a location specific 
       QR code on it. Open the location pin for the real world landmark 
       you are physically standing near and read the description. 
      </p>
      <p>
        Use the pink “Scan Location” button in the map to scan the 2nd QR code on 
        the poster, to mark that you have visited it (make sure you are logged in!). 
        Refresh the map page to see your progress! 
      </p>
    </>
  );
};

export default RulesPage;
