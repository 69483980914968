import styled from "styled-components";

const AdminPage = ({ userArray }) => {
  if (!userArray) {
    return <h2>Loading</h2>;
  }

  return (
    <StyledAdminPage>
      <h2>Admin page</h2>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Locations Visited</th>
          </tr>
        </thead>
        <tbody>
          {userArray.map((eachUser) => (
            <tr key={eachUser._id}>
              <td>{eachUser.user.name}</td>
              <td>{eachUser.user.email}</td>
              <td>{eachUser.visitedLocations.length}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledAdminPage>
  );
};

const StyledAdminPage = styled.div`
  position: absolute;
  height: auto;
  width: 100%;
  top: 100px;
  padding: 2rem;
  z-index: 10;
  background-color: #f2f2f2;
  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      border-bottom: 1px solid #ddd;
    }

    th {
      background-color: #f2f2f2;
      font-weight: bold;
    }
  }
`;

export default AdminPage;
