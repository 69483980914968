import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";


const Header = ({
  adminPopup,
  userArray,
  setRulesOpen,
  rulesOpen,
  displayDirections,
  setDisplayDirections,
  setNextPage,
}) => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const openRules = () => {
    setNextPage(false);
    setRulesOpen(!rulesOpen);
  };

  const openDirections = () => {
    setDisplayDirections(!displayDirections);
  };

  return (

    <StyledHeader>
      <Title isAuthenticated={isAuthenticated}>ConU Food Revolution Game</Title>
      <ButtonBar>
        <div>
          <Button onClick={openRules}>Rules</Button>
          <Button onClick={openDirections}>Route</Button>
        </div>

        <LoginBar>
          { isAuthenticated && user?.name && <Welcome>Welcome, {user.name.split(' ')[0]}</Welcome>}
          {userArray.length > 0 && <button onClick={adminPopup}>admin</button>}
          <Button onClick={isAuthenticated ? () => logout() : () => loginWithRedirect()}>
            {isAuthenticated ? 'Sign Out' : ' Sign In'}
          </Button>  
        </LoginBar>
      </ButtonBar>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  background-color: transparent;
  height: 10vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 1vw;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 95vw;
  }
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80vw;
  @media (max-width: 768px) {
    width: 95vw;
  }
`;

const Button = styled.button`
  background-color: #cbff9e;
  transition: background-color 0.3s ease;
  margin-right: 8px;
  color: #474747;
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.2s ease;
  @media (max-width: 768px) {
    left: 2.5vw;
  }
  :active {
    background-color: #fff;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const LoginBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  p {
    margin-right: 1rem;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  transition: all 0.2s ease;
  font-size: 2rem;
  :hover {
    color: #fe46a5;
    text-shadow: rgba(254, 70, 165, 0.4) 5px 5px,
      rgba(254, 70, 165, 0.3) 10px 10px, rgba(254, 70, 165, 0.2) 15px 15px,
      rgba(254, 70, 165, 0.1) 20px 20px, rgba(254, 70, 165, 0.05) 25px 25px;
  }
  @media (max-width: 560px) {
    font-size: 1.2rem;
  }
`;

const Welcome = styled.p`
  font-size: 12px;
  align-content: center;
`;

export default Header;
