import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import moment from "moment";

const Profile = ({ profilePopup, visitedLocations, scannerPopup }) => {
  const { user } = useAuth0();

  const getInitials = () => {
    const initials = user.name ? user.name.charAt(0).toUpperCase() : "";
    return initials;
  };

  if (!user) {
    return <h2>Profile Loading</h2>;
  }
  return (
    <StyledProfile>
      <StyledH2>
        <h2>{user.given_name ? user.given_name : getInitials()}</h2>
      </StyledH2>
      <ContentDiv>
        <p>Joined {moment(user.updated_at).format("MMMM Do YYYY")}</p>
        {visitedLocations && (
          <>
            <h3>Scavenger Hunt Progress</h3>
            <ProgressBarBg>
              <ProgressBar length={visitedLocations.length}>
                {visitedLocations.length === 0 ? <p>No locations visited</p> : <p>{visitedLocations.length} / 16</p>}
              </ProgressBar>
            </ProgressBarBg>
          </>
        )}
        {/* <h3>Prize Progress</h3>
        <p>What goes here?</p> */}
        {/* <ScanLocation scannerPopup={scannerPopup} /> */}
      </ContentDiv>

      <StyleCloseBut onClick={profilePopup}>X</StyleCloseBut>
    </StyledProfile>
  );
};

const ProgressBarBg = styled.div`
  background-color: #707070;
  width: 100%;
  height: 2rem;
  border-radius: 100px;
  margin-top: 10px;
`;
const ProgressBar = styled.div`
  background-color: #6e9c59;
  width: calc((${(props) => props.length} / 14) * 100%);
  height: 2rem;
  border-radius: 100px;
  margin-top: 10px;
  display: flex;
  padding-bottom: 8px;
  align-items: center; /* Aligns content vertically */
  P {
    color: #fff;
    font-weight: bold;
    padding-left: 1rem;
    white-space: nowrap;
  }
`;
const ContentDiv = styled.div`
  margin-top: 25%;
  padding: 2rem;
  h3 {
    margin-top: 1rem;
  }
  @media (max-width: 500px) {
    margin-top: 0;
  }
`;
const StyledH2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 25%;
  background-color: #63628e;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    color: #fff;
    font-weight: 400;
    font-size: 5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @media (max-width: 500px) {
    position: relative;
  }
`;

const StyleCloseBut = styled.button`
  position: absolute;
  z-index: 2;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
`;

const StyledProfile = styled.div`
  flex-direction: column;
  position: fixed;
  z-index: 10;
  left: 50%;
  top: 12%;
  transform: translateX(-50%);
  z-index: 1;
  width: 500px;
  height: 600px;
  font-size: 1.1rem;
  background-color: #fff9f3;
  button {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    :hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
  p {
    padding-top: 10px;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 100vh;
    top: 0;
  }
`;

export default Profile;
