import ReactMapBox, { Marker, GeolocateControl } from "react-map-gl";
import styled from "styled-components";
import { useEffect, useState } from "react";
import PopupComponent from "./PopupComponent";
import gps from "./assets/gps.png";
import markerblue from "./assets/marker-blue.png";
import markeryellow from "./assets/marker-yellow.png";
import { PacmanLoader } from "react-spinners";
import Profile from "./Profile";
import ProfilePicture from "./ProfilePicture";
import Scanner from "./Scanner";


const MapContainer = ({ user, isAuthenticated, scannerPopup, scannerOpen }) => {
  const { REACT_APP_MAPBOX_TOKEN } = window.__RUNTIME_CONFIG__;
  const [art, setArt] = useState(null);
  const [selectedArt, setSelectedArt] = useState(null);
  const [visitedLocations, setVisitedLocations] = useState(null);
  const [profileOpen, setProfileOpen] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/get-art`)
      .then((res) => res.json())
      .then((data) => {
        setArt(data.data);
      });
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/get-locations-visited`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: user.sub }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 200) {
            setVisitedLocations(data.data);
          } else {
            console.error("Failed to fetch visited locations");
          }
        })
        .catch((error) => {
          console.error(
            "An error occurred while fetching visited locations:",
            error
          );
        });
    }
  }, [isAuthenticated, user]);

  const rightClick = () => {
    setSelectedArt(null);
  };

  const profilePopup = () => {
    setProfileOpen((prevState) => !prevState);
  };

  if (!art) {
    return (
      <StyledLoad>
        <div>
          <h1>Map is Loading</h1>
          <PacmanLoader color="#fff" />
        </div>
      </StyledLoad>
    );
  }

  return (
    <StyledMap>
      <ReactMapBox
        initialViewState={{
          longitude: -73.579032,
          latitude: 45.496368,
          zoom: 16,
        }}
        mapboxAccessToken={REACT_APP_MAPBOX_TOKEN}
        mapStyle={"mapbox://styles/alltherighthype/clk1cpopv003b01pw6zvuegbo"}
        onContextMenu={rightClick}
      >
        {user && (
          <button onClick={profilePopup}>
            <ProfilePicture picture={user.picture} name={user.name} />
          </button>
        )}
        {profileOpen && (
          <Profile
            profilePopup={profilePopup}
            visitedLocations={visitedLocations}
            scannerPopup={scannerPopup}
          />
        )}
        {scannerOpen && <Scanner scannerPopup={scannerPopup} />}

        {!(selectedArt || profileOpen || scannerOpen) && (
          <>
            <GeolocateControl showAccuracyCircle={false} />
            ScaleControl <StyledGps src={gps} alt="Geolocating button" />
          </>
        )}

        {art.map((each) => {
          const isVisited =
            isAuthenticated &&
            visitedLocations?.some(
              (location) => location.locationId === each._id
            );
          const markerSrc = isVisited ? markerblue : markeryellow;
          let visitedAt;
          if (visitedLocations) {
            const visitedLocation = visitedLocations.find(
              (location) => location.locationId === each._id
            );
            visitedAt = visitedLocation?.visitedAt;
          }

          return (
            <Marker
              latitude={each.result.latitude}
              longitude={each.result.longitude}
              key={each._id}
            >
              <StyledBut
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedArt({
                    name: each.result.name,
                    _id: each._id,
                    embedId: each.result.embedId,
                    description: each.result.description,
                    hyperlink: each.result.hyperlink,
                    org: each.result.org,
                    role: each.result.role,
                    address: each.result.address,
                    visited: Boolean(visitedAt),
                    visitedAt: visitedAt,
                    imgSrc: each.result.imgSrc,
                    but: each.result.but,
                    directionsFrom: each.result.directionsFrom,
                    directionsTo: each.result.directionsTo
                  });
                }}
              >
                <StyledMarker
                  src={markerSrc}
                  alt={
                    isVisited
                      ? "A pink and green marker - you have visited this location"
                      : "A gray marker - you have not visited this location"
                  }
                />
                <StyledLabel>{each.result.name}</StyledLabel>
              </StyledBut>
            </Marker>
          );
        })}
        {selectedArt && (
          <PopupComponent
            selectedArt={selectedArt}
            setSelectedArt={setSelectedArt}
            scannerPopup={scannerPopup}
          />
        )}
      </ReactMapBox>
    </StyledMap>
  );
};

const StyledLabel = styled.div`
font-size: .6rem;
font-weight: 600;
color: #474747;
margin-top: -35px;
`

const StyledMarker = styled.img`
  height: 70px;
  transform: translateY(-50%);
`;

const StyledGps = styled.img`
  z-index: 2;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
`;
const StyledLoad = styled.div`
  margin-top: 100px;
  position: relative;
  padding: 10%;
`;

const StyledMap = styled.div`
  margin: 8px 10vw 8vh 10vw;
  height: 80vh;
  width: 80vw;
  box-shadow: rgba(254, 70, 165, 0.4) 5px 5px, rgba(254, 70, 165, 0.3) 10px 10px,
    rgba(254, 70, 165, 0.2) 15px 15px, rgba(254, 70, 165, 0.1) 20px 20px,
    rgba(254, 70, 165, 0.05) 25px 25px;
  @media (max-width: 768px) {
    width: auto;
    margin: 8px 2.5vw 8vh 2.5vw;
  }
`;

const StyledBut = styled.button`
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack marker and label vertically */
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  background-color: transparent;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 10px;
  border: none;
`;

export default MapContainer;
