import styled from "styled-components";
import { QrReader } from "react-qr-reader";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Scanner = ({ scannerPopup }) => {
  const [data, setData] = useState("No result");
  const { user } = useAuth0();

  const updateVisited = async (locationId) => {
    try {
      //${process.env.REACT_APP_BACKEND_URL}
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-visited/${locationId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: user.sub }),
      });
      if (response.ok) {
        const responseData = await response.json();
        window.alert("Location Scanned!!! Refresh the page to see your progress!");
        // Right HERE!!
      } else {
        throw new Error(
          `Failed to update visited status: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data !== "No result") {
      updateVisited(data).then(() => {

        scannerPopup();
      });
    }
  }, [data, scannerPopup]);

  const handleQrReaderResult = (result, error) => {
    if (!!result) {
      if (result.text.startsWith("http")) {
        window.alert("This is the wrong code, scan the other one!");
      } else {
        setData(result?.text);
      }
    }

    if (!!error) {
      console.info(error);
    }
  };

  return (
    <StyledScanner>
      <StyledH2>
        <h2>Scan Location!</h2>
      </StyledH2>
      <StyledQrReaderDiv>
        <QrReader
          onResult={handleQrReaderResult}
          style={{ width: "100%" }}
          constraints={{
            facingMode: "environment",
          }}
        />
      </StyledQrReaderDiv>
      <StyleCloseBut onClick={scannerPopup}>X</StyleCloseBut>
    </StyledScanner>
  );
};

const StyledQrReaderDiv = styled.div`
  margin-top: -64px;
  @media (max-width: 786px) {
    margin-top: 0;
  }
`;

const StyledH2 = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 25%;
  background-color: #63628e;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    color: #fff;
    font-weight: 400;
    font-size: 3rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledScanner = styled.div`
  flex-direction: column;
  position: fixed;
  z-index: 12;
  left: 50%;
  top: 12%;
  transform: translateX(-50%);
  width: 500px;
  height: 500px;
  font-size: 1.1rem;
  background-color: #fff9f3;
  button {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    :hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
  p {
    padding-top: 10px;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 80vh;
    font-size: 8%;
  }
`;

const StyleCloseBut = styled.button`
  position: absolute;
  z-index: 2;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
`;

export default Scanner;
