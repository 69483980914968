import styled from "styled-components";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import ScanLocation from "./ScanLocation";
import Reggies from "./assets/Reggies.jpeg";
import CafeX from "./assets/CafeX.jpeg";
import CityFarm from "./assets/CityFarm.jpeg";
import CityFarm2 from "./assets/CityFarm2.jpeg";
import HiveCafe from "./assets/HiveCafe.jpeg";
import HiveCafe3 from "./assets/HiveCafe3.jpeg";
import HiveFreeLunch from "./assets/HiveFreeLunch.jpeg";
import MotherHubbards from "./assets/MotherHubbards.jpeg";
import Potato from "./assets/Potato.jpeg";
import Potato2 from "./assets/Potato2.jpeg";
import CultivAction from "./assets/CultivAction.jpeg";
import CultivAction2 from "./assets/CultivAction2.jpeg";
import FrigoVert from "./assets/FrigoVert.jpeg";
import Greenhouse from "./assets/Greenhouse.jpeg";
import Greenhouse2 from "./assets/Greenhouse2.png";
import StudentUnionCoffeeHouse from "./assets/StudentUnionCoffeeHouse.jpeg";
import ConcordiaFarmersMarket from "./assets/ConcordiaFarmersMarket.png";


const PopupComponent = ({ selectedArt, setSelectedArt, scannerPopup }) => {
  const { isAuthenticated } = useAuth0();
  let imageSrc;
  switch (selectedArt.imgSrc) {
    case "Reggies":
      imageSrc = Reggies;
      break;
    case "CafeX":
      imageSrc = CafeX;
      break;
    case "CityFarm":
      imageSrc = CityFarm;
      break;
    case "CityFarm2":
      imageSrc = CityFarm2;
      break;
    case "HiveCafe":
      imageSrc = HiveCafe;
      break;
    case "HiveCafe3":
      imageSrc = HiveCafe3;
      break;
    case "HiveFreeLunch":
      imageSrc = HiveFreeLunch;
      break;
    case "MotherHubbards":
      imageSrc = MotherHubbards;
      break;
    case "Potato":
      imageSrc = Potato;
      break;
    case "Potato2":
      imageSrc = Potato2;
      break;
    case "CultivAction":
      imageSrc = CultivAction;
      break;
    case "CultivAction2":
      imageSrc = CultivAction2;
      break;
    case "FrigoVert":
      imageSrc = FrigoVert;
      break;
    case "Greenhouse":
      imageSrc = Greenhouse;
      break;
    case "Greenhouse2":
      imageSrc = Greenhouse2;
      break;
    case "StudentUnionCoffeeHouse":
      imageSrc = StudentUnionCoffeeHouse;
      break;
    case "ConcordiaFarmersMarket":
      imageSrc = ConcordiaFarmersMarket;
      break;
    default:
      imageSrc = ""; // Default image source
  }

  return (
    <StyledInfo>
      <StyleCloseBut onClick={() => setSelectedArt(null)}>X</StyleCloseBut>

      <StyledHeader imageSrc={imageSrc}>
        <LocationName>{selectedArt.name}</LocationName>
      </StyledHeader>

      <StyledContent>
        {!isAuthenticated ? (
          <StyledVisited>
            Please log in to mark this location as visited
          </StyledVisited>
        ) : selectedArt.visited ? (
          <StyledVisited>
            You visited this location on{" "}
            {moment(selectedArt.visitedAt).format("MMMM Do YYYY")}
          </StyledVisited>
        ) : (
          <StyledVisited>You have not visited this location!</StyledVisited>
        )}

        <p>
          {selectedArt.description}
        </p>
        <StyledRow>
          <a
            href={selectedArt.hyperlink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {selectedArt.but}
          </a>
          <ScanLocation scannerPopup={scannerPopup} />
        </StyledRow>
        <StyledRow>
          <StyledList>
            <li>{selectedArt.org}</li>
            <li>{selectedArt.address}</li>
          </StyledList>
          {selectedArt.embedId && (
              <ResponsiveIframe
              src={`https://www.youtube.com/embed/${selectedArt.embedId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></ResponsiveIframe>
          )}
        </StyledRow>

        {selectedArt.directionsTo && selectedArt.directionsFrom &&
          <Directions>
            <h3>Pin Check in Location & Suggested Route Directions:</h3>
            <h3>Getting here from…</h3>
            <p>{selectedArt.directionsFrom}</p>
            <h3>Getting from here to the next location…</h3>
            <p>{selectedArt.directionsTo}</p>
          </Directions>
        }
      </StyledContent>
    </StyledInfo>
  );
};


const StyledInfo = styled.div`
  overflow: auto;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  left: 50%;
  top: 12%;
  transform: translateX(-50%);
  width: 500px;
  height: 82%;
  font-size: 0.9rem;
  background-color: #fff9f3;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  button {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    :hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    top: 0;
    font-size: 95%;
  }
  @media (max-height: 768px) {
    width: 100%;
    height: 100%;
    top: 0;
    font-size: 95%;
    line-height: 0.9rem;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
    top: 0;
    font-size: 80%;
    line-height: 0.8rem;
    li {
      padding-right: 1rem;
    }
  }
`;

const StyledHeader = styled.div`
  height: 25%;
  text-align: center;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: center; /* Center vertically */
  align-items: center;
  background-color: #fe46a5;
  background-image: url(${(props) => props.imageSrc});
  background-size: cover;
  background-position: center;
  h2 {
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.2; /* Adjust line height for better visual spacing */
    margin: 0; /* Remove default margin */
  }
`;

const LocationName = styled.h2`
  background-color: black;
  color: white;
  padding: 0.5rem;
  border-radius: 10px;
`;

const StyledRow = styled.div`
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 0 0 0;
  overflow: hidden; 
`;

const StyledList = styled.ul`
  font-weight: 600;
  width: 35%;
  list-style-type: none;
  padding-left: 0;
  li {
    padding-top: 1rem;
  }
  @media (max-width: 768px) {
    bottom: 150px;
  }
  @media (max-height: 768px) {
    bottom: 150px;
  }
`;
const Directions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
`;

const StyledVisited = styled.p`
  color: #777;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
`;

const StyleCloseBut = styled.button`
  position: absolute;
  z-index: 2;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
`;

const StyledContent = styled.div`
  padding: 20px;
  position: relative;
  overflow: auto;
`;

const ResponsiveIframe = styled.iframe`
  height: auto;

  max-width: 312px;
  @media (max-width: 768px) {
    max-width: 80vw;
  }
`;

export default PopupComponent;
