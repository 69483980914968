import styled from "styled-components";
import { useState } from "react";
import ReactMapBox, { Marker } from "react-map-gl";
import { useEffect } from "react";


const NewCrossing = () => {
  const { REACT_APP_MAPBOX_TOKEN } = window.__RUNTIME_CONFIG__;
  const [form, setForm] = useState({});
  const [art, setArt] = useState(null);


  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/get-art`)
      .then((res) => res.json())
      .then((data) => {
        setArt(data.data);
      });
  }, []);

  const handleChange = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  //${process.env.REACT_APP_BACKEND_URL}
  const handleSubmit = () => {
    if (!form.latitude || !form.longitude) {
      alert("Please select a location on the map");
      return;
    }
    fetch(`${process.env.REACT_APP_BACKEND_URL}/make-crossing`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...form }),
    })
      .then((res) => res.json())
      .then((data) => {});
  };
  const handleClick = (e) => {
    e.preventDefault();
    setForm({ ...form, latitude: e.lngLat.lat, longitude: e.lngLat.lng, visited: false, });
  };

  if (!art) {
    return <h1>Loading</h1>;
  }
  return (
    <StyledForm>
      <p>Find your crossing on the map, and click on it.</p>
      <form onSubmit={handleSubmit}>
        <input
          onChange={(e) => handleChange(e.target.id, e.target.value)}
          placeholder="name your art"
          id="name"
          type="text"
          required
        ></input>
        <input
          onChange={(e) => handleChange(e.target.id, e.target.value)}
          placeholder="description"
          id="description"
          type="text"
          required
        ></input>
        <input
          onChange={(e) => handleChange(e.target.id, e.target.value)}
          placeholder="youtube id"
          id="embedId"
          type="text"
          required
        ></input>
        <input
          onChange={(e) => handleChange(e.target.id, e.target.value)}
          placeholder="hyperlink"
          id="hyperlink"
          type="text"
          required
        ></input>
        <input
          onChange={(e) => handleChange(e.target.id, e.target.value)}
          placeholder="years active"
          id="org"
          type="text"
          required
        ></input>
        <input
          onChange={(e) => handleChange(e.target.id, e.target.value)}
          placeholder="address"
          id="address"
          type="text"
          required
        ></input>

        <StyledSubmit>Submit</StyledSubmit>
      </form>
      <ReactMapBox
        initialViewState={{
          longitude: -73.579032,
          latitude: 45.496368,
          zoom: 16,
        }}
        mapboxAccessToken={REACT_APP_MAPBOX_TOKEN}
        mapStyle={"mapbox://styles/mapbox/streets-v12"}
        onClick={(e) => handleClick(e)}
      >
        {art.map((each) => {
          return (
            <Marker
              latitude={each.result.latitude}
              longitude={each.result.longitude}
              key={each._id}
            >
              <StyledMarker>{each.result.name}</StyledMarker>
            </Marker>
          );
        })}
        {form.latitude && (
          <Marker latitude={form.latitude} longitude={form.longitude}></Marker>
        )}
      </ReactMapBox>
    </StyledForm>
  );
};
const StyledSubmit = styled.button`
  padding: 5px;
  border: 2px solid white;
  width: 35%;
  margin: 5px auto;
`;
const StyledMarker = styled.p`
  background-color: #fff9f3;
  padding: 5px;
`;
const StyledForm = styled.div`
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff9f3;
  width: 700px;
  height: 500px;
  form {
    display: flex;
    flex-direction: column;
    input {
      background-color: #fff9f3;
    }
    button {
      background-color: #fff9f3;
      cursor: pointer;
    }
  }
`;

export default NewCrossing;
