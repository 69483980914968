import { useState } from "react";
import styled from "styled-components";
import LandingPage from "./LandingPage";
import RulesPage from "./RulesPage";
import RulesPage2 from "./RulesPage2";
import Login from "./Login";
import Footer from "./Footer";


const RulesPopup = ({ setRulesOpen, nextPage, setNextPage }) => {
  const [rulesPage1, setRulesPage1] = useState(true);

  return (
    <RulesDiv>
      <CloseBut onClick={() => setRulesOpen(false)}>X</CloseBut>
      {nextPage ? <LandingPage /> : rulesPage1 ? <RulesPage /> : <RulesPage2 />}
      <ButDiv>
        {nextPage && (
          <button onClick={() => setNextPage(false)}>Read the Rules</button>
        )}
        {!nextPage && (
          <button onClick={() => setRulesPage1(!rulesPage1)}>
            {rulesPage1 ? "Go to Page 2" : "Back to Page 1"}
          </button>
        )}
        <Login />
      </ButDiv>
      <StyledFooter>
        <Footer />
      </StyledFooter>
    </RulesDiv>
  );
};

const StyledFooter = styled.div`
  display: none;
  @media (max-height: 600px) {
    display: flex;
  }
`;

const CloseBut = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #cbff9e;
  color: #474747;
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.2s ease;
`;
const RulesDiv = styled.div`
  position: absolute;
  width: 80%;
  height: 60vh;
  top: 150px;
  background-color: #ffd1e8;
  z-index: 111;
  padding: 3rem;
  text-align: center;
  position: absolute;
  right: 10vw;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  p {
    margin-top: 2rem;
  }
  @media (max-width: 1100px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    top: 70px;
    right: 2.5vw;
    left: 2.5vw;
    width: auto;
    height: 80vh;
    padding: 1rem;
    transform: translateX(0);
  }
  @media (max-height: 800px) {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transform: translateX(0);
    height: 100vh;
    width: 100%;
    font-size: 80%;
  }
  @media (max-width: 500px) {
    top: 0;
    right: 0;
    left: 0;
    transform: translateX(0);
    height: 100vh;
    font-size: 80%;
  }
  @media (max-width: 300px) {
    font-size: 60%;
  }
`;
const ButDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  button {
    margin: 10px;
    background-color: #cbff9e;
    color: #474747;
    border-radius: 5px;
    font-weight: 600;
    transition: all 0.2s ease;
    :hover {
      color: #fe46a5;
    }
  }
`;

export default RulesPopup;
