import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";


const Login = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  return (
    !isAuthenticated && (
      <StyledLogin onClick={() => loginWithRedirect()}>Sign In</StyledLogin>
    )
  );
};

const StyledLogin = styled.button`
  box-shadow: rgba(254, 70, 165, 0.4) 5px 5px, rgba(254, 70, 165, 0.3) 10px 10px,
    rgba(254, 70, 165, 0.2) 15px 15px, rgba(254, 70, 165, 0.1) 20px 20px,
    rgba(254, 70, 165, 0.05) 25px 25px;
  background-color: #CBFF9E;
  color: #474747;
  font-weight: 600;
  font-size: 20px;
  transition: all 0.2s ease;
  padding: 5px 7px;
  border-radius: 5px;
  width: 90px;
  :hover {
    background-color: #fff;
    color: #fe46a5;
    box-shadow: rgba(254, 70, 165, 0.6) 5px 5px, rgba(254, 70, 165, 0.4) 8px 8px,
    rgba(254, 70, 165, 0.3) 11px 11px, rgba(254, 70, 165, 0.2) 14px 14px,
    rgba(254, 70, 165, 0.1) 17px 17px;
    transform: translateY(2px);
  }
`;

export default Login;
