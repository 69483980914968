import styled from "styled-components";


const DirectionsDialog = ({ setDisplayDirections }) => {

  return (
    <DirectionsDiv>
      <CloseButton onClick={() => setDisplayDirections(false)}>X</CloseButton>
      <>
        <h2>Route</h2>
        <p>Below is a suggested route you can follow to do the entire tour and check in at all locations to complete the game!</p>
        <p>You can also find directions at the bottom of each location pop up window.</p>
        <p>
          If you want to use a google map route,  
          <a target="_blank" href={`https://maps.app.goo.gl/ahufvmmTMbgsziV66`}>
            click here for an autogenerated one!
          </a>
        </p>

        <LocationSubtitle>CFC & Mother Hubbard's</LocationSubtitle>
        <p>
          Start here at 2100 rue Mackay (the PR Annex) across from the west entrance to the Hall building. 
          Right beside the map pin location at 2090 Mackay, you’ll find the posters for these two check ins at 2100 Mackay. 
        </p>

        <LocationSubtitle>CFC & Mother Hubbard's to QPIRG</LocationSubtitle>
        <p>
          From the CFC office, turn right to go down Mackay to de Maisonneuve, turn right on de Maisonneuve and right again onto Guy. The QPIRG office is in the tall building near the Starbucks, on the second floor in office 205.
        </p>

        <LocationSubtitle>QPIRG to Frigo Vert</LocationSubtitle>
        <p>
          Walk on rue Guy and turn left on to de Maisonneuve, then right onto Mackay. The Frigo Vert will be on your right 
        </p>

        <LocationSubtitle>Frigo Vert to Café X</LocationSubtitle>
        <p>
          Continue walking down Mackay all the way down to René-Lévesque, then turn left. You will find the room where Café X used to be in the Fine Arts building, down the hallway on the 2nd floor. 
        </p>

        <LocationSubtitle>Café X to Student Union Coffee House</LocationSubtitle>
        <p>
          When you exit the Fine Arts building, walk to Crescent and turn left. Walk back up to de Maisonneuve. Here you will pass the old location of the Student Union Coffee House, but the actual QR code to scan for this historic location can be found later in the Hive.
        </p>

        <LocationSubtitle>Student Union Coffee House to the Hive</LocationSubtitle>
        <p>
          Turn left onto de Maisonneuve and enter the Hall building from the front doors, or from Bishop (on the right). Take the escalator up one "floor" to reach the mezzanine, where you can access the Hive.
        </p>

        <LocationSubtitle>The Hive to Reggie's</LocationSubtitle>
        <p>
        Exit the Hive back into the mezzanine, turn right and walk down the corridor (through the glass fire doors) and you'll find Reggie's bar on the right, before leaving the building exit doors.
        </p>

        <LocationSubtitle>Reggie's to the Greenhouse</LocationSubtitle>
        <p>
        Find the elevators on the Mezzanine, or take the escalators in front of the Hive cafe, up to the 7th floor. Navigate toward the CSU lounge, near H711, to find the temporary Greenhouse space to your left.
        </p>

        <LocationSubtitle>The Greenhouse to People's Potato</LocationSubtitle>
        <p>
        Continue walking down the hallway after you pass the Greenhouse, navigate back toward the elevators to find the People's Potato on the same floor.
        </p>

        <LocationSubtitle>People's Potato to Farmers' Market</LocationSubtitle>
        <p>
        Go back downstairs to exit the Hall Building from the front entrance, onto de Maisonneuve (across from the Library). Navigate to the corner of Mackay and de Maisonneuve where the Concordia Farmers' Market is held weekly on Wednesdays (until the end of October).
        </p>

        <LocationSubtitle>Farmers' Market to CFC/Mother Hubbard's</LocationSubtitle>
        <p>
        Turn right onto Mackay (toward the Hall building) and find 2100 where the CFC office and Mother Hubbard's posters are located.
        </p>
      </>
    </DirectionsDiv>
  );
};


const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #cbff9e;
  color: #474747;
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.2s ease;
`;
const DirectionsDiv = styled.div`
  overflow-y: scroll;
  position: absolute;
  width: 80%;
  height: 60vh;
  top: 150px;
  background-color: #ffd1e8;
  z-index: 111;
  padding: 1rem 3rem;
  text-align: center;
  position: absolute;
  right: 10vw;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  p {
    margin-top: 0.5rem;
  }
  @media (max-width: 1100px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    top: 70px;
    right: 2.5vw;
    left: 2.5vw;
    width: auto;
    height: 80vh;
    padding: 1rem;
    transform: translateX(0);
  }
  @media (max-height: 800px) {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transform: translateX(0);
    height: 100vh;
    width: 100%;
    font-size: 80%;
  }
  @media (max-width: 500px) {
    top: 0;
    right: 0;
    left: 0;
    transform: translateX(0);
    height: 100vh;
    font-size: 80%;
  }
  @media (max-width: 300px) {
    font-size: 60%;
  }
`;

const LocationSubtitle = styled.h4`
  margin-top: 1rem;
`;

export default DirectionsDialog;
